import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Project.css";

import Bandage from "../images/bandge.png";
import Robohash from "../images/Robohash.png";
import Desert from '../images/Desert.png';
import Calculator from '../images/Calculator.png';

const projectList = [
  {
    title: 'Bandage',
    description: 'An ecommerce website.',
    image: Bandage,
    url: 'https://bandage-six.vercel.app/'
  },
  {
    title: 'Robohash',
    description: 'A mini robot website',
    image: Robohash,
    url: 'https://robohash-app.vercel.app/'
  },
  {
    title: 'Desserts',
    description: 'A food e-commerce Website',
    image: Desert,
    url: 'https://desserts-ashen.vercel.app/'
  },
  {
    title: 'Calculator',
    description: 'Calculator',
    image: Calculator,
    url: 'https://calculator-ivory-pi.vercel.app/'
  },
];

function Projects() {
  // State to hold the search Query
  const [searchQuery, setSearchQuery] = useState('');

  // Filter the Projects based on the searchQuery
  const filteredProjects = projectList.filter(project => 
    project.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    project.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <section id="projects" className="py-5">
      <div className="container">
        <h2 className="text-center mb-4">My Projects</h2>
        <div className='sch mb-4'>
          <input 
            type="search" 
            placeholder='Search Projects' 
            value={searchQuery} 
            onChange={(e) => setSearchQuery(e.target.value)} 
            className="form-control"
          />
        </div>
        <div className="row">
          {filteredProjects.map((project, index) => (
            <Link 
              to={project.url} 
              target='_blank' 
              className="col-md-6 col-lg-4 mb-4 text-decoration-none" 
              key={index}
            >
              <div className="card h-100">
                <img src={project.image} className="card-img-top" height={250} alt={project.title} />
                <div className="card-body">
                  <h5 className="card-title">{project.title}</h5>
                  <p className="card-text">{project.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
